import React from "react";

const Notifications = (props) => {
    return(
        <div className={"notification " + props.classList} >
            <div>
                <p className="">{props.title}</p>
                <p className="sub">{props.message}</p>
            </div>
        </div>
    )
}

export default Notifications;