import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import Navigation from '../Navigation';

const HeaderWidget = () => {
    // const [blogDetail, setBlogDetail] = useState('');
    // const [isLoaded, setIsLoaded] = useState(true);
    const [navOpen, setNavOpen] = useState('');
    const sectionRef = useRef();
    const gsapTriggerSection = '.home-hero-section';

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        gsap.set("body", {
            scrollTrigger: {
                trigger: gsapTriggerSection,
                start: "top center",
                end: "bottom top",
                ease: "ease",
                scrub: true,
                markers: false,
                onEnter: () => document.getElementById('site-header').classList.remove('sticky-header'),
                onLeave: () => document.getElementById('site-header').classList.add('sticky-header'),
                onEnterBack: () => document.getElementById('site-header').classList.remove('sticky-header'),
                onLeaveBack: () => document.getElementById('site-header').classList.add('sticky-header'),
            }
        });
    }, []);

    const handleClick = () => {
        if(navOpen === 'open-nav'){
            setNavOpen('')
        }
        else{
            setNavOpen('open-nav')
        }
    }

    useEffect(() => {
        // axios.get(`${process.env.REACT_APP_API_BASE_URL}/wp-json/`)
        //   .then(res => {
        //     const blogDetail = res.data;
        //     setBlogDetail({ ...blogDetail });
        //     setIsLoaded(true);
        // })
    }, []);

    // if(isLoaded){
        return (
            <header className={"site-header " + navOpen} id='site-header' ref={sectionRef}> 
                <div className='container'>
                    <div className='logo-section'>
                        <Link to='/' className="blog-title">Maker Ventures</Link>
                    </div>
                    <div className='navigation-block' 
                        onClick={() => {
                            setNavOpen('')
                        }}
                    >
                        <Navigation />
                    </div>
                    <div className='menu-btn' onClick={handleClick}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </header>   
        );
    // }
    // return null;
};

export default HeaderWidget;