import React from 'react';
import ReactHtmlParser from 'react-html-parser';

// Text Animation
import AddInview from '../../components/AddInview';

const Navigation = () => {
    return (
        <>
            <nav className='site-navigation'>
                <ul>
                    <li>
                        <a href='#what-we-do'><AddInview text='what we do' classList='fade up dn'/></a>
                    </li>
                    <li>
                        <a href='#how-it-works'><AddInview text='How it works' classList='fade up dn'/></a>
                    </li>
                    <li>
                        <a href='#who-we-invest-in'><AddInview text='Who we invest in' classList='fade up dn'/></a>
                    </li>
                    <li>
                        <a href='#testimonials'><AddInview text='Testimonials' classList='fade up dn'/></a>
                    </li>
                </ul>
            </nav>
            {/* <a href='/' className='btn header-animate-btn'><AddInview text='' classList='button-bg d4'/><AddInview text='Get Funded' classList='fade up dn'/></a> */}
            <AddInview text={ReactHtmlParser("<a href='/' class='btn third-btn'>Get Funded</a>")} classList="fade up dn" />
        </>
    );
};

export default Navigation;