import React, {useState, useEffect} from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import ReactPlayer from 'react-player';

// Assets Import
import PlayButton from '../../assets/images/play-btn.svg'
import PlayButtonBG from '../../assets/images/play-btn-bg.svg'

const HomeVideoBlock = (props) => {
    const [inView, setInView] = useState(false);
    const [isPlay, setInPlay] = useState(false);
    // const sectionRef = useRef();
    // const gsapTriggerSection = '.home-hero-section .video-block';
    // const gsapAnimateSection = '.home-hero-section .video-block';
    // const playButtonAnimation = '.home-hero-section .play-btn';

    useEffect(() => {
        // gsap.registerPlugin(ScrollTrigger)
        // gsap.to(gsapAnimateSection, {
        //     // scale: 1,
        //     translateX: 0,
        //     opacity: 1,
        //     scrollTrigger: {
        //         trigger: gsapTriggerSection,
        //         start: "top 80%",
        //         end: "bottom 40%",
        //         ease: "back.out(2)",
        //         scrub: true,
        //         markers: false,
        //     }
        // });
        // gsap.to(playButtonAnimation, {
        //     scale: 1,
        //     rotate: 0,
        //     opacity: 1,
        //     scrollTrigger: {
        //         trigger: gsapTriggerSection,
        //         start: "top 80%",
        //         end: "bottom 40%",
        //         ease: "back.out(2)",
        //         scrub: true,
        //         markers: false,
        //     }
        // });
    }, []);

    return (
        <VisibilitySensor
            partialVisibility
            onChange={(isVisible) => {
                if(inView){
                    return
                }
                setInView(isVisible)
            }}
        >
            <div className={"video-block " + (inView ? "in-view" : "")}>
                <div className="video-thumbnail">
                    {/* <img src={props.videoThumbnailImage} alt='' className='dn'/> */}
                    {/* <iframe src="https://player.vimeo.com/video/229083908?h=ab9fa652d8&title=0&byline=0&portrait=0" width="770px" height="446" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe> */}
                    <ReactPlayer 
                        width="770px" 
                        height="440px" 
                        className='dn' 
                        id="video" 
                        url='https://player.vimeo.com/external/229083908.m3u8?s=6f6b05094366deee50c242fa3c48de90862ecf49' 
                        playing={isPlay} 
                        onClick={() => {
                            setInPlay(!isPlay)
                        }}
                    />
                    {/* <video id="header-video" playing={isPlay}  preload="" loop="" muted="" data-func="play_video" width="770px" height="430px">
                        <source src="https://player.vimeo.com/external/229083908.m3u8?s=6f6b05094366deee50c242fa3c48de90862ecf49" type="application/x-mpegURL"/>
                        <source src="https://player.vimeo.com/external/229083908.hd.mp4?s=78934a4d79f3ec867825c46631771161b7f5d537&amp;profile_id=174" type="video/mp4" media="(min-device-pixel-ratio:2), (-webkit-min-device-pixel-ratio:2), (min--moz-device-pixel-ratio:2), (-o-min-device-pixel-ratio:2)"/>
                        <source src="https://player.vimeo.com/external/229083908.hd.mp4?s=78934a4d79f3ec867825c46631771161b7f5d537&amp;profile_id=174" type="video/mp4" media="(max-device-pixel-ratio:1), (-webkit-max-device-pixel-ratio:1), (max--moz-device-pixel-ratio:1), (-o-max-device-pixel-ratio:1)"/>
                    </video> */}
                    <span className={"layer d5 " + (isPlay ? "playing" : "not-playing")} onClick={() => {
                        setInPlay(!isPlay)
                    }}></span>
                </div>
                <div className={(isPlay ? "playing" : "not-playing")}>
                    <div className='play-video d1' onClick={() => {
                        setInPlay(!isPlay)
                    }}>
                        <div className='play-btn'><img src={PlayButton} alt="Play Video" /></div>
                        <div className='play-btn-bg'><img src={PlayButtonBG} alt="Play Video" /></div>
                    </div>
                </div>
            </div>
        </VisibilitySensor>
    );
};

export default HomeVideoBlock;