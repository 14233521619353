import React, {useState} from 'react';
import ReactHtmlParser from 'react-html-parser';
import VisibilitySensor from 'react-visibility-sensor';

// Fade Animation
import FadeUp from '../../components/FadeUp';

const WhatWeDoSection = (props) => {
    const [inView, setInView] = useState(false);
    return (
        <VisibilitySensor
            partialVisibility
            onChange={(isVisible) => {
                if(inView){
                    return
                }
                setInView(isVisible)
            }}
        >
            <div className='list-element seperate'>
                <span className={'bordered-section dn '+ (inView ? "in-view" : "")}></span>
                <div>
                    <h3 className='h3'>
                        <FadeUp
                            text={ReactHtmlParser(props.secondoryHeading)}
                            classList="d1 up"
                        />
                    </h3>
                    <p className='large'>
                        <FadeUp
                            text={ReactHtmlParser(props.secondoryDescription)}
                            classList="d2 up"
                        />
                    </p>
                </div>
                <div>
                    <p>
                        <FadeUp
                            text={ReactHtmlParser(props.detail)}
                            classList="d2 up"
                        />
                    </p>
                </div>
            </div>
        </VisibilitySensor>
    );
};

export default WhatWeDoSection;