import React, {useEffect, useRef} from 'react';
import ReactHtmlParser from 'react-html-parser';
// import VisibilitySensor from 'react-visibility-sensor';

// Assets Import
import SideElement2 from '../../assets/images/side-element-2.svg' 
import CircleElement2 from '../../assets/images/circle-element-2.svg' 

// Animations Import
import MonthlyRevenue from '../../assets/animations/monthly-revenue.json'
import FlatFee from '../../assets/animations/flat-fee-of-6-12.json'
import FlexibleRepaymentsByRevenueShare from '../../assets/animations/flexible-repayments-by-revenue-share.json'
import YearsRepayment from '../../assets/animations/years-repayment.json'
import SeedInvestment from '../../assets/animations/seed-investment.json'
import RevenueUpTo3xCap from '../../assets/animations/revenue-up-to-3x-cap.json'
import Arrow from '../../assets/animations/what-we-do-bg-arrow.json'


// Sections Import
import AnimateImage from '../../components/AnimateImage';
import WhatWeDoMainBoxes from '../../components/WhatWeDoMainBoxes';
import WhatWeDoSeperateBox from '../../components/WhatWeDoSeperateBox'

// Fade Animation
import FadeUp from '../../components/FadeUp';
import SlideUp from '../../components/SlideUp';

const WhatWeDoSection = (props) => {
    const sectionRef = useRef();

    const LottieDefaultOptions = [
        [
            {
                loop: false,
                autoplay: false, 
                animationData: MonthlyRevenue,
            },
            {
                loop: false,
                autoplay: false, 
                animationData: FlatFee,
            },
            {
                loop: false,
                autoplay: false, 
                animationData: FlexibleRepaymentsByRevenueShare,
            }
        ],
        [
            {
                loop: false,
                autoplay: false, 
                animationData: YearsRepayment,
            },
            {
                loop: false,
                autoplay: false, 
                animationData: SeedInvestment,
            },
            {
                loop: false,
                autoplay: false, 
                animationData: RevenueUpTo3xCap,
            }
        ]
    ];

    const ArrowLottieDefaultOptions = {
        loop: false,
        autoplay: false, 
        animationData: Arrow,
    };
    useEffect(() => {
        // gsap.registerPlugin(ScrollTrigger)
        // gsap.set("body", {
        //     // className: '+=light-bg',
        //     scrollTrigger: {
        //         trigger: gsapTriggerSection,
        //         start: "top center",
        //         end: "bottom top",
        //         ease: "ease",
        //         scrub: true,
        //         markers: false,
        //         onEnter: () => document.body.classList.add('light-bg'),
        //         onLeave: () => document.body.classList.remove('light-bg'),
        //         onEnterBack: () => document.body.classList.add('light-bg'),
        //         onLeaveBack: () => document.body.classList.remove('light-bg'),
        //     }
        // });
        // gsap.to('.what-we-do-section .row > div .bordered-section', {
        //     translateX: 0,
        //     translateY: 0,
        //     opacity: 1,
        //     scrollTrigger: {
        //         trigger: gsapTriggerSection,
        //         start: "top center",
        //         end: "bottom 50%",
        //         ease: "back.out(2)",
        //         scrub: true,
        //         markers: false,
        //     }
        // });
     }, []);
    return (
        <section className='what-we-do-section' ref={sectionRef} id="what-we-do">
            <img src={SideElement2} className="side-element-2" alt='' />
            <img src={CircleElement2} className="circle-element-2" alt='' />
            <div className='arrow'>
                <AnimateImage 
                    animationOptions={ArrowLottieDefaultOptions}
                    time='800'
                    speed={1}
                />
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-1'></div>
                    <div className='col-lg-10'>
                        <h4 className='h4 pre-title'>
                            <FadeUp
                                text={ReactHtmlParser(props.preTitle)}
                                classList="dn up"
                            />
                        </h4>    
                        <h2 className='h2 section-title'>
                            {/* <SlideUp 
                                text='<span class="outer"><span class="inner dn">Empowering</span></span> <span class="outer"><span class="inner dn">founders</span></span> <span class="outer"><span class="inner dn">with</span></span> <span class="outer"><span class="inner dn">non</span></span> <span class="outer"><span class="inner dn">dilutive</span></span> <span class="outer"><span class="inner dn">capital</span></span> <span class="outer"><span class="inner dn">and</span></span> <span class="outer"><span class="inner dn">execution</span></span> <span class="outer"><span class="inner dn">expertise</span></span>'
                            /> */}
                            <FadeUp
                                text={ReactHtmlParser("Empowering founders with non dilutive capital and execution expertise")}
                                classList="dn up"
                            />
                        </h2>    
                        <p className='section-description large'>
                            <FadeUp
                                text={ReactHtmlParser(props.description)}
                                classList="dn up"
                            />
                        </p>
                    </div>
                    <div className='col-lg-1'></div>
                </div>
                <div className='row'>
                    {props.list.map(function(object, i){
                        return (
                            <WhatWeDoMainBoxes
                                key={i}
                                title={ReactHtmlParser(object.title)}
                                description={ReactHtmlParser(object.description)}
                                list={object.list}
                                lottieDefaultOptions={LottieDefaultOptions[i]}
                            />
                        );
                    })}
                </div>
                <WhatWeDoSeperateBox
                    secondoryHeading={props.secondoryHeading}
                    secondoryDescription={props.secondoryDescription}
                    detail={props.detail}
                />
            </div>
        </section>
    );
};

export default WhatWeDoSection;