import React, {useEffect, useRef} from 'react';
import ReactHtmlParser from 'react-html-parser';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Widgets Import
import BackgroundParticlesAnimation from '../../components/BackgroundParticlesAnimation';

// Animations Import
import DigitalBusinessesWithEarlyTraction from '../../assets/animations/digital-businesses-with-early-traction.json'

// Sections Import
import AnimateImage from '../../components/AnimateImage';

// Fade Animation
import FadeUp from '../../components/FadeUp';
import SlideUp from '../../components/SlideUp';


const WhoWeInvestInSection = (props) =>{
    const sectionRef = useRef();
    const gsapTriggerSection = '.who-we-invest-in-section';

    const LottieDefaultOptions = {
        loop: false,
        autoplay: false, 
        animationData: DigitalBusinessesWithEarlyTraction,
    };
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        gsap.set("body", {
            // className: '+=primary-bg',
            scrollTrigger: {
                trigger: gsapTriggerSection,
                start: "top center",
                end: "bottom top",
                ease: "ease",
                scrub: true,
                markers: false,
                onEnter: () => document.body.classList.add('primary-bg'),
                onLeave: () => document.body.classList.remove('primary-bg'),
                onEnterBack: () => document.body.classList.add('primary-bg'),
                onLeaveBack: () => document.body.classList.remove('primary-bg'),
            }
        });
    }, []);
    return(
        <section className="who-we-invest-in-section" ref={sectionRef} id="who-we-invest-in">
            <BackgroundParticlesAnimation/>
            <div className="container">
                <div className="row">
                    <div className='col-lg-7'>
                        <h4 className='h4 pre-title'>
                            <FadeUp
                                text={ReactHtmlParser(props.preTitle)}
                                classList="up dn"
                            />
                        </h4>    
                        <h2 className='h2 section-title light-color'>
                            {/* <SlideUp
                                text='<span class="outer"><span class="inner dn">Digital</span></span> <span class="outer"><span class="inner dn">businesses</span></span> <br><span class="outer"><span class="inner dn">with </span></span> <span class="outer"><span class="inner dn">early </span></span> <span class="outer"><span class="inner dn">traction</span></span>'
                            /> */}
                            <FadeUp
                                text={ReactHtmlParser("Digital businesses <br>with early traction")}
                                classList="up dn"
                            />
                        </h2> 
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="list-block">
                                    {props.list.map(function(object, i){
                                        return (
                                            <div className="list" key={i}>
                                                <p className="large fourth-color">
                                                    <FadeUp
                                                        text={ReactHtmlParser(object.title)}
                                                        classList="up dn"
                                                    />
                                                </p>
                                                <p className='light-color'>
                                                    <FadeUp
                                                        text={ReactHtmlParser(object.description)}
                                                        classList="up d1"
                                                    />
                                                </p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="image-block">
                            {/* <img src={props.image.url} alt={props.image.url} /> */}
                            <AnimateImage 
                                animationOptions={LottieDefaultOptions}
                                time='800'
                                speed={1}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default WhoWeInvestInSection;