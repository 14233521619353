import React, {useState, useEffect, useRef} from 'react';
import ReactHtmlParser from 'react-html-parser';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import VisibilitySensor from 'react-visibility-sensor';

// Assets Import
import CircleElement1 from '../../assets/images/circle-element-1.svg' 
import OurDifferenceBGArrows from '../../assets/animations/our-diffrences-bg-arrows.json'

// Fade Animation
import FadeUp from '../../components/FadeUp';
import SlideUp from '../../components/SlideUp';
import AnimateImage from '../../components/AnimateImage';

const TraditionalFundingTableSection = (props) =>{
    const sectionRef = useRef();
    const gsapTriggerSection = '.traditional-funding-table-section';
    const [inView, setInView] = useState(false);

    const BGArrowsLottieDefaultOptions = {
        loop: false,
        autoplay: false, 
        animationData: OurDifferenceBGArrows,
    }

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        gsap.set("body", {
            // className: '+=white-bg',
            scrollTrigger: {
                trigger: gsapTriggerSection,
                start: "top center",
                end: "bottom top",
                ease: "ease",
                scrub: true,
                markers: false,
                onEnter: () => {document.body.classList.add('white-bg');},
                onLeave: () => document.body.classList.remove('white-bg'),
                onEnterBack: () => {document.body.classList.add('white-bg');},
                onLeaveBack: () => document.body.classList.remove('white-bg'),
            }
        });
     }, []);
    return(
        <VisibilitySensor
            partialVisibility
            onChange={(isVisible) => {
                if(inView){
                    return
                }
                setInView(isVisible)
            }}
        >
            <section className="traditional-funding-table-section" ref={sectionRef}>
                <div className='side-arrow'>
                    <AnimateImage 
                        animationOptions={BGArrowsLottieDefaultOptions}
                        time='100'
                        speed={1}
                    />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8">
                            <h4 className='h4 pre-title'>
                                <FadeUp
                                    text={ReactHtmlParser(props.preTitle)}
                                    classList="up dn"
                                />
                            </h4>    
                            <h2 className='h2 section-title'>
                                {/* <SlideUp
                                    text='<span class="outer"><span class="inner dn">Access</span></span> <span class="outer"><span class="inner dn">capital</span></span> <span class="outer"><span class="inner dn">that</span></span> <span class="outer"><span class="inner dn">complements</span></span> <span class="outer"><span class="inner dn">traditional</span></span> <span class="outer"><span class="inner dn">funding</span></span> <span class="outer"><span class="inner dn">options</span></span>'
                                /> */}
                                <FadeUp
                                    text={ReactHtmlParser("Access capital that complements traditional funding options")}
                                    classList="up dn"
                                />
                            </h2> 
                            <p className='section-description'>
                                <FadeUp
                                    text={ReactHtmlParser(props.description)}
                                    classList="up dn"
                                />
                            </p>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                    <div className={"table-wrapper for-desktop " + (inView ? "in-view" : "")}>
                        <div className="table-header">
                            <div className="table-row">
                                <div className="table-col"></div>
                                <div className="table-col">
                                    <p>
                                        <FadeUp
                                            text='Maker Ventures'
                                            classList="up dn"
                                        />
                                    </p>
                                </div>
                                <div className="table-col">
                                    <p>
                                        <FadeUp
                                            text='VC'
                                            classList="up dn"
                                        />
                                    </p>
                                </div>
                                <div className="table-col">
                                    <p>
                                        <FadeUp
                                            text='Banks'
                                            classList="up dn"
                                        />
                                    </p>
                                </div>
                                <div className="table-col">
                                    <p>
                                        <FadeUp
                                            text='Venture Debts'
                                            classList="up dn"
                                        />
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="table-body">
                            {props.list.map(function(object, i){
                                return (
                                    <div className='table-row' key={i}>
                                        <div className="table-col">
                                            <p>
                                                <FadeUp
                                                    text={ReactHtmlParser(object.label_name)}
                                                    classList="left dn"
                                                />
                                            </p>
                                        </div>
                                        <div className="table-col">
                                            <p>
                                                <FadeUp
                                                    text={ReactHtmlParser(object.maker_venture_value)}
                                                    classList="up dn"
                                                />
                                            </p>
                                        </div>
                                        <div className="table-col">
                                            <p>
                                                <FadeUp
                                                    text={ReactHtmlParser(object.vc_value)}
                                                    classList="up dn"
                                                />
                                            </p>
                                        </div>
                                        <div className="table-col">
                                            <p>
                                                <FadeUp
                                                    text={ReactHtmlParser(object.banks_value)}
                                                    classList="up dn"
                                                />
                                            </p>
                                        </div>
                                        <div className="table-col">
                                            <p>
                                                <FadeUp
                                                    text={ReactHtmlParser(object.venture_debts_value)}
                                                    classList="up dn"
                                                />
                                            </p>
                                        </div>
                                    </div>            
                                );
                            })}
                            <img src={CircleElement1} alt="" className="circle-element-1" />
                        </div>
                        <div className="table-footer">
                            <div className="table-col"></div>
                            <div className="table-col"></div>
                            <div className="table-col"></div>
                            <div className="table-col"></div>
                            <div className="table-col"></div>
                        </div>
                    </div>
                    {/**Mobile version table*/}
                    <div className="table-wrapper for-mobile">
                        <div className="bordered-section">
                            <h3 className="h3">Maker Venture</h3>
                            {props.list.map(function(object, i){
                                return (
                                    <div className='table-row' key={i}>
                                        <div className="table-col">
                                            <p>{ReactHtmlParser(object.label_name)}</p>
                                        </div>
                                        <div className="table-col">
                                            <p>{ReactHtmlParser(object.maker_venture_value)}</p>
                                        </div>
                                    </div>            
                                );
                            })}
                        </div>
                        <div className="bordered-section">
                            <h3 className="h3">VC</h3>
                            {props.list.map(function(object, i){
                                return (
                                    <div className='table-row' key={i}>
                                        <div className="table-col">
                                            <p>{ReactHtmlParser(object.label_name)}</p>
                                        </div>
                                        <div className="table-col">
                                            <p>{ReactHtmlParser(object.vc_value)}</p>
                                        </div>
                                    </div>            
                                );
                            })}
                        </div>
                        <div className="bordered-section">
                            <h3 className="h3">Banks</h3>
                            {props.list.map(function(object, i){
                                return (
                                    <div className='table-row' key={i}>
                                        <div className="table-col">
                                            <p>{ReactHtmlParser(object.label_name)}</p>
                                        </div>
                                        <div className="table-col">
                                            <p>{ReactHtmlParser(object.banks_value)}</p>
                                        </div>
                                    </div>            
                                );
                            })}
                        </div>
                        <div className="bordered-section">
                            <h3 className="h3">Venture Debts</h3>
                            {props.list.map(function(object, i){
                                return (
                                    <div className='table-row' key={i}>
                                        <div className="table-col">
                                            <p>{ReactHtmlParser(object.label_name)}</p>
                                        </div>
                                        <div className="table-col">
                                            <p>{ReactHtmlParser(object.venture_debts_value)}</p>
                                        </div>
                                    </div>            
                                );
                            })}
                        </div>
                    </div>
                </div>
            </section>
        </VisibilitySensor>
    )
}

export default TraditionalFundingTableSection;