import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

const AddInview = (props) => {
    const [inView, setInView] = useState(false);
    return (
        <VisibilitySensor
            partialVisibility
            onChange={(isVisible) => {
                setTimeout(() => {
                    if(inView){
                        return
                    }
                    setInView(isVisible)
                }, 100);
            }}
        >
            <span className={props.classList +" " + (inView ? "in-view" : "")}>{props.text}</span>
        </VisibilitySensor>
    );
};

export default AddInview;