import React, {useState} from 'react';
import ReactHtmlParser from 'react-html-parser';
import VisibilitySensor from 'react-visibility-sensor';

// Sections Import
import WhatWeDoBoxes from '../../components/WhatWeDoBoxes';

// Fade Animation
import FadeUp from '../../components/FadeUp';

const WhatWeDoMainBoxes = (props) => {
    const [inView, setInView] = useState(false);
    return (
        <VisibilitySensor
            partialVisibility
            onChange={(isVisible) => {
                if(inView){
                    return
                }
                setInView(isVisible)
            }}
        >
            <div className='col-lg-6'>
                <div className='list-element'>
                    <span className={"bordered-section dn " + (inView ? "in-view" : "")}></span>
                    <h3 className='h3'>
                        <FadeUp
                            text={ReactHtmlParser(props.title)}
                            classList="dn up"
                        />
                    </h3>
                    <p className="list-description">
                        <FadeUp
                            text={ReactHtmlParser(props.description)}
                            classList="d1 up"
                        />
                    </p>
                    <div className='list-block row'>
                        {props.list.map(function(object1, j){
                            return (
                                <div className='col-md-4' key={j}>
                                    <WhatWeDoBoxes
                                        animationOptions={props.lottieDefaultOptions[j]}
                                        time="0"
                                        text={ReactHtmlParser(object1.title)}
                                        textClassList="d1 up"
                                        classList="dn"
                                    />
                                </div>
                            );
                        })}    
                    </div>    
                </div>            
            </div>
        </VisibilitySensor>
    );
};

export default WhatWeDoMainBoxes;