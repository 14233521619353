import React, {useEffect, useRef} from 'react';
import ReactHtmlParser from 'react-html-parser';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Assets Import

// Animations Import
import DataDriven from '../../assets/animations/data-driven.json'
import FlexibleFunding from '../../assets/animations/flexible-funding.json'
import ValueAccelerating from '../../assets/animations/value-accelerating.json'
import OurDifferenceBGArrows from '../../assets/animations/our-diffrences-bg-arrows.json'

// Sections Import
import AnimateImage from '../../components/AnimateImage';
import OurDifferenceBoxes from '../../components/OurDifferenceBoxes';

// Fade Animation
import FadeUp from '../../components/FadeUp';
import SlideUp from '../../components/SlideUp';


const OurDifferenceSection = (props) => {
    const sectionRef = useRef();
    const gsapTriggerSection = '.our-difference-section';
    
    const BGArrowsLottieDefaultOptions = {
        loop: false,
        autoplay: false, 
        animationData: OurDifferenceBGArrows,
    }

    const LottieDefaultOptions = [
        {
            loop: false,
            autoplay: false, 
            animationData: DataDriven,
        },
        {
            loop: false,
            autoplay: false, 
            animationData: FlexibleFunding,
        },
        {
            loop: false,
            autoplay: false, 
            animationData: ValueAccelerating,
        }
    ];
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        gsap.set("body", {
            // className: '+=secondary-bg',
            scrollTrigger: {
                trigger: gsapTriggerSection,
                start: "top center",
                end: "bottom 80%",
                ease: "ease",
                scrub: true,
                markers: false,
                onEnter: () => document.body.classList.add('secondary-bg'),
                onLeave: () => document.body.classList.remove('secondary-bg'),
                onEnterBack: () => document.body.classList.add('secondary-bg'),
                onLeaveBack: () => document.body.classList.remove('secondary-bg'),
            }
        });
     }, []);
    return (
        <section className='our-difference-section' ref={sectionRef} id="our-difference">
            {/* <img src={BorderRadius} className="top-radius" /> */}
            <div className='side-arrow'>
                <AnimateImage 
                    animationOptions={BGArrowsLottieDefaultOptions}
                    time='100'
                    speed={1}
                />
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-2'></div>
                    <div className='col-lg-8'>
                        <h4 className='h4 pre-title light-color'>
                            <FadeUp
                                text={ReactHtmlParser(props.preTitle)}
                                classList="up dn"
                            />
                        </h4>    
                        <h2 className='h2 section-title'>
                            {/* <SlideUp
                                text='<span class="outer"><span class="inner dn">Fair</span></span> <span class="outer"><span class="inner dn">capital,</span></span> <span class="outer"><span class="inner dn">hassle</span></span> <span class="outer"><span class="inner dn">free</span></span>'
                            /> */}
                            <FadeUp
                                text={ReactHtmlParser("Fair capital, hassle free")}
                                classList="up dn"
                            />
                        </h2>    
                        <p className='section-description light-color'>
                            <FadeUp
                                text={ReactHtmlParser(props.description)}
                                classList="up d2"
                            />
                        </p>
                    </div>
                    <div className='col-lg-2'></div>
                </div>
                <div className='row list-block'>
                    {props.list.map(function(object, i){
                        return (
                            <div className='col-lg-4' key={i}>
                                <OurDifferenceBoxes
                                    animationOptions={LottieDefaultOptions[i]}
                                    title={ReactHtmlParser(object.title)}
                                    description={ReactHtmlParser(object.description)}
                                />          
                            </div>
                        );
                    })}
                </div>
            </div>
            {/* <img src={BorderRadius} className="bottom-radius" /> */}
        </section>
    );
};

export default OurDifferenceSection;