import React from 'react';
// import { Link } from 'react-router-dom';
// import axios from 'axios';

// import APIUrl from '../../globalVariables/APIUrl';

import FooterNavigation from '../FooterNavigation';

// Widgets Import
import HomeFooterForm from '../HomeFooterForm';

// Text Animation
import FadeUp from '../../components/FadeUp';

const FooterWidget = () => {
    // const [blogDetail, setBlogDetail] = useState('');
    // const [isLoaded, setIsLoaded] = useState(false);
    // const [navOpen, setNavOpen] = useState('');

    // useEffect(() => {
    //     axios.get(`${process.env.REACT_APP_API_BASE_URL}/wp-json/`)
    //       .then(res => {
    //         const blogDetail = res.data;
    //         setBlogDetail({ ...blogDetail });
    //         setIsLoaded(true);
    //     })
    // }, []);

    // if(isLoaded){
        
    // }
    // return null;

    return (
        <footer className="site-footer"> 
            <div className='container'>
                <div className='form-section'>
                    <div className='row'>
                        <div className='col-lg-3'></div>
                        <div className='col-lg-6'>
                            <h3 className='light-color'>
                                <FadeUp
                                    text='Get Funded Today!'
                                    classList='up dn'
                                />
                            </h3>
                            <HomeFooterForm/>
                        </div>
                        <div className='col-lg-3'></div>
                    </div>
                </div>
            </div>
            <hr />
            <div className='container'>
                <div className='navigation-block'>
                    <FooterNavigation />
                </div>
            </div>
            <hr />
            <div className='container'>
                <div className='sub-footer'>
                    <div className='links'>
                        <a href='/'>
                            <FadeUp
                                text='Privacy Policy'
                                classList='left dn'
                            />
                        </a>
                        <FadeUp
                            text='|'
                            classList='left dn'
                        />
                        <a href='/'>
                            <FadeUp
                                text='Terms of service'
                                classList='left dn'
                            />
                        </a>
                    </div>
                    <div className='copyright-text'>
                        <FadeUp
                            text={'© Maker Ventures. All Rights Reserved ' + new Date().getFullYear()}
                            classList='left dn'
                        />
                    </div>
                </div>
            </div>
        </footer>   
    );
};

export default FooterWidget;