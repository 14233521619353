import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

// Sections Import
import AnimateIcon from '../../components/AnimateIcon';

// Fade Animation
import FadeUp from '../../components/FadeUp';

const OurDifferenceBoxes = (props) => {
    const [inView, setInView] = useState(false);
    return (
        <VisibilitySensor
            partialVisibility
            onChange={(isVisible) => {
                if(inView){
                    return
                }
                setInView(isVisible)
            }}
        >
            <div className={"list " + (inView ? "in-view" : "")}>
                <div className='bordered-section dn'></div>
                <div className='icon'>
                    <AnimateIcon 
                        animationOptions={props.animationOptions}
                        time='0'
                        speed={2}
                    />
                </div>
                <h3 className='h3'>
                    <FadeUp
                        text={props.title}
                        classList="up d3"
                    />
                </h3>
                <p className="list-description">
                    <FadeUp
                        text={props.description}
                        classList="up d4"
                    />
                </p>
            </div> 
        </VisibilitySensor>
    );
};

export default OurDifferenceBoxes;