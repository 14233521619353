import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import { UseEffectScroll } from 'react-use-smooth-scroll'

// Scroll to top
import ScrollToTop from './ScrollToTop';

// Config
// import baseConfig from '../src/webpack.config'

// Pages
import Home from './Pages/Home';

// Header Import
import HeaderWidget from './components/HeaderWidget';

// Footer Import
import FooterWidget from './components/FooterWidget';

// Custom Cursor
import CustomCursor from './components/CustomCursor';


const Root = () => {
  return (
    <BrowserRouter>
      <ScrollToTop/>
      {/* <UseEffectScroll> */}
      <HeaderWidget />
      
      {/* <CustomCursor /> */}
      <Routes>
        <Route path="/" element={<Home />}>
          <Route index element={<Home />} />
        </Route>
      </Routes>
      <FooterWidget/>
      {/* </UseEffectScroll> */}
      {/* </ScrollToTop> */}
    </BrowserRouter>
  );
};

export default Root;
