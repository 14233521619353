import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

const AddInviewImage = (props) => {
    const [inView, setInView] = useState(false);
    return (
        <VisibilitySensor
            partialVisibility
            onChange={(isVisible) => {
                if(inView){
                    return
                }
                setInView(isVisible)
            }}
        >
            <img src={props.src} className={props.classList +" " + (inView ? "in-view" : "")}/>
        </VisibilitySensor>
    );
};

export default AddInviewImage;