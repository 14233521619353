// import React from 'react';
import React, { useState, useEffect } from 'react';
// import axios from 'axios';

// Assets Import
import videoThumbnail from '../../assets/images/video-thumbnail.png';
import TestimonialHeadshot from '../../assets/images/Andrew-Schneider.png';

// Sections Import
import HomeHeroSection from '../../sections/HomeHeroSection';
import WhatWeDoSection from '../../sections/WhatWeDoSection';
import OurDifferenceSection from '../../sections/OurDifferenceSection';
import HowItWorkSection from '../../sections/HowItWorkSection';
import WhoWeInvestInSection from '../../sections/WhoWeInvestInSection';
import TestimonialSection from '../../sections/TestimonialSection';
import TraditionalFundingTableSection from '../../sections/TraditionalFundingTableSection';

// Assets Import



const Home = () => {
    // const [homeDetail, setHomeDetail] = useState('');
    // const [isLoaded, setIsLoaded] = useState(false);

    // useEffect(() => {
    //     axios.get(`${process.env.REACT_APP_API_BASE_URL}/wp-json/wp/v2/pages/11`)
    //       .then(res => {
    //         const homeDetail = res.data;
    //         setHomeDetail({ ...homeDetail });
    //         setIsLoaded(true);
    //     })
    // }, []);
    const WhatWeDoSectionList = [
        {
            title: 'Maker Advances',
            description: 'Fund recurring growth expenses e.g. customer acquisition, inventory',
            list: [
                {
                    title: '3X monthly revenue',
                },
                {
                    title: 'Flat fee of 6-12%',
                },
                {
                    title: 'Flexible repayments by revenue share',
                }
            ]
        },
        {
            title: 'Maker Seed',
            description: 'Fund one-offs to scale e.g. product, hiring',
            list: [
                {
                    title: '3-4 years repayment',
                },
                {
                    title: '$10-100k seed investment',
                },
                {
                    title: '2% of revenue up to 3X cap',
                }
            ]
        }
    ]
    const OurDifferenceSectionList = [
        {
            title: 'Data Driven',
            description: 'Our mission is to level the playing field for early stage growth capital. We provide capital that is unbiased, flexible and non dilutive with the execution support to accelerate value creation',
        },
        {
            title: 'Flexible Funding',
            description: 'Funding that flexes as revenue grows with transparent costs and does not require dilution, personal guarantees or pitch decks',
        },
        {
            title: 'Value Accelerating',
            description: 'On demand expertise to supercharge growth in areas that are hard to access for early stage founders',
        }
    ]
    const HowItWorkSectionList = [
        {
            title: 'Connect',
            description: 'Register and qualify for funding in less than 5 mins. Connect revenue and marketing platforms you already use.',
        },
        {
            title: 'Get Funded',
            description: 'Get a term sheet with upto 3 funding offers. Select option that best suits and complete onboarding to receive funds in days',
        },
        {
            title: 'Support Growth',
            description: 'Access curated feed of actionable insights and on demand execution expertise. Auto qualify for follow on funding as you grow',
        }
    ]
    const WhoWeInvestInSectionList = [
        {
            title: 'Digital Business Model',
            description: 'Digital business model e.g. SaaS, subscription, D2C, apps',
        },
        {
            title: 'Revenue Generating',
            description: 'Revenue generating for 6 months, > $1k in monthly recurring revenue',
        },
        {
            title: 'Support Growth',
            description: 'Industry expertise: Fintech, Health, Deep tech, B2B software',
        },
        {
            title: 'International Expansion',
            description: 'Registered in Australia and Singapore (initially) with appetite for international expansion',
        },
    ]
    const TraditionalFundingTableSectionList = [
        {
            label_name: 'Access',
            maker_venture_value: 'Revenue generating',
            vc_value: 'Few',
            banks_value: 'Few',
            venture_debts_value: 'Few',
        },
        {
            label_name: 'Speed',
            maker_venture_value: 'Days',
            vc_value: '3-6 months',
            banks_value: '2-3 months',
            venture_debts_value: '2-6 months',
        },
        {
            label_name: 'Cost',
            maker_venture_value: 'Flat fee',
            vc_value: 'Dilution',
            banks_value: 'Compound Interest',
            venture_debts_value: '+ Warrants',
        },
        {
            label_name: 'Risk',
            maker_venture_value: 'None',
            vc_value: 'Loss of Control',
            banks_value: 'Personal Guarantees',
            venture_debts_value: 'Personal Guarantees',
        },
        {
            label_name: 'Execution Support',
            maker_venture_value: 'Days',
            vc_value: '3-6 months',
            banks_value: '2-3 months',
            venture_debts_value: '2-6 months',
        },
    ]
    const TestimonialsList = [
        {
            name: 'Andrew Schneider',
            designation: 'Director,  <span>X YZ</span>',
            message: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat',
            image: TestimonialHeadshot,
        },
        {
            name: 'Andrew Schneider',
            designation: 'Director,  <span>X YZ</span>',
            message: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat',
            image: TestimonialHeadshot,
        },
        {
            name: 'Andrew Schneider',
            designation: 'Director,  <span>X YZ</span>',
            message: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat',
            image: TestimonialHeadshot,
        },
        {
            name: 'Andrew Schneider',
            designation: 'Director,  <span>X YZ</span>',
            message: 'Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat',
            image: TestimonialHeadshot,
        }
    ]
    // if(isLoaded){
        
    // }
    // return null;
    
    useEffect(() => {
        window.history.scrollRestoration = 'manual'
    }, []);

    return (
        <div className='page-content'>
            {/* { document.body.classList.remove('light-bg-header') } */}
            <div className='main-content'>
                
                <HomeHeroSection
                    // heroImage={homeDetail.acf.block[0].image}
                    // mainHeading='<span class="third-color"><span class="outer"><span class="inner dn">Redefine</span></span> <span class="outer"><span class="inner d1">how</span></span> <br><span class="outer"><span class="inner d2">you</span></span> <span class="outer"><span class="inner d3">grow</span></span> <span class="outer"><span class="inner d4">your</span></span></span><br><span class="outer"><span class="inner d5">digital</span></span> <span class="outer"><span class="inner d6">business</span></span>'
                    description='Revenue based funding and execution support designed for early stage founders'
                    videoThumbnailImage={videoThumbnail}
                />
                <WhatWeDoSection
                    preTitle='What we do'
                    // mainHeading='<span class="outer"><span class="inner dn">Empowering</span></span> <span class="outer"><span class="inner dn">founders</span></span> <span class="outer"><span class="inner dn">with</span></span> <span class="outer"><span class="inner dn">non</span></span> <span class="outer"><span class="inner dn">dilutive</span></span> <span class="outer"><span class="inner dn">capital</span></span> <span class="outer"><span class="inner dn">and</span></span> <span class="outer"><span class="inner dn">execution</span></span> <span class="outer"><span class="inner dn">expertise</span></span>'
                    description='Amet minim mollit non deserunt ullamco est sit aliqu'
                    list={WhatWeDoSectionList}
                    secondoryHeading='Maker Growth Support'
                    secondoryDescription='Data Insights and full stack expertise to supercharge growth'
                    detail='Actionable data insights by connecting revenue, marketing and social media platforms <br/><br/>On demand execution expertise at cost or revenue share across proposition design, engineering, marketing analytics, partnerships, brand, intellectual property, market expansion, talent management'
                />
                <OurDifferenceSection
                    preTitle='Our difference'
                    // mainHeading='<span class="outer"><span class="inner dn">Fair</span></span> <span class="outer"><span class="inner dn">capital,</span></span> <span class="outer"><span class="inner dn">hassle</span></span> <span class="outer"><span class="inner dn">free</span></span>'
                    description='Our mission is to level the playing field for early stage growth capital. <br> We provide capital that is unbiased, flexible and non dilutive with the execution support to accelerate value creation.'
                    list={OurDifferenceSectionList}
                />
                <HowItWorkSection
                    preTitle='How it works'
                    mainHeading='Unlocking potential along the growth journey'
                    list={HowItWorkSectionList}
                />
                <WhoWeInvestInSection
                    preTitle='Who we invest in'
                    mainHeading='<span class="fourth-color">Digital businesses</span><br/>with early traction'
                    list={WhoWeInvestInSectionList}
                    // image={homeDetail.acf.block[4].image}
                />
                <TraditionalFundingTableSection
                    preTitle='Who we invest in'
                    mainHeading='Access capital that complements traditional funding options'
                    description='Our mission is to level the playing field for early stage growth capital. We provide capital that is unbiased, flexible and non dilutive with the execution support to accelerate value creation.'
                    list={TraditionalFundingTableSectionList}
                />
                <TestimonialSection
                    preTitle='Testimonials'
                    mainHeading='Customer Stories'
                    description='Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officiaserunerun'
                    list={TestimonialsList} 
                />
            </div>
        </div>
    );
};

export default Home;