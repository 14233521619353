import React from 'react';
import { Link } from 'react-router-dom';

// Text Animation
import AddInview from '../../components/AddInview';

const FooterNavigation = () => {
    return (
        <nav className='site-navigation'>
            <ul>
                <li>
                    <Link to='/'><AddInview text='Home' classList='fade up dn'/></Link>
                </li>
                <li>
                    <a href='#what-we-do'><AddInview text='what we do' classList='fade up dn'/></a>
                </li>
                <li>
                    <a href='#how-it-works'><AddInview text='How it works' classList='fade up dn'/></a>
                </li>
                <li>
                    <a href='#who-we-invest-in'><AddInview text='Who we invest in' classList='fade up dn'/></a> 
                </li>
                <li>
                    <a href='#testimonials'><AddInview text='Testimonials' classList='fade up dn'/></a>
                </li>
            </ul>
        </nav>
    );
};

export default FooterNavigation;