import React, { useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import VisibilitySensor from 'react-visibility-sensor';

// Assets Import

// Animations Import
import PointArrow from '../../assets/animations/point-arrow.json'

// Sections Import
import AnimateIcon from '../../components/AnimateIcon';

// Fade Animation
import FadeUp from '../../components/FadeUp';

const HowItWorksBoxes = (props) => {
    const [inView, setInView] = useState(false);
    const LottieDefaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: PointArrow,
    };
    return (
        <VisibilitySensor
            partialVisibility
            onChange={(isVisible) => {
                if(inView){
                    return
                }
                setInView(isVisible)
            }}
        >
            <div className={"list " + (inView ? "in-view" : "")}>
                <div className='bordered-section dn'></div>
                <div className="arrow">
                    <AnimateIcon 
                        animationOptions={LottieDefaultOptions}
                        time='0'
                        speed={2}
                    />
                </div>
                <div className='content'>
                    <p className='sub'>
                        <strong>
                            <FadeUp
                                text={props.count}
                                classList="right d2"
                            />
                        </strong>
                    </p>
                    <h3 className='h3'>
                        <FadeUp
                            text={ReactHtmlParser(props.title)}
                            classList="right d3"
                        />
                    </h3>
                    <p className="list-description">
                        <FadeUp
                            text={ReactHtmlParser(props.description)}
                            classList="right d4"
                        />
                    </p>
                </div>
                <div className='icon'>
                    {/* <img src={object.icon.url} alt={object.icon.alt} /> */}
                    <AnimateIcon 
                        animationOptions={props.animationOptions}
                        time={props.time}
                        speed={2}
                    />
                </div>
            </div> 
        </VisibilitySensor>
    );
};

export default HowItWorksBoxes;