import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import Slider from "react-slick";

// Fade Animation
import FadeUp from '../../components/FadeUp';
import SlideUp from '../../components/SlideUp';
import AddInviewImage from '../../components/AddInviewImage';

const TestimonialSection = (props) => {
    const settings = {
        dots: true,
        // infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0',
        responsive: [
            {
                breakpoint: 991,
                settings: {
                  slidesToShow: 1,
                }
            }
        ]
    };
    return (
        <section className='testimonial-section' id="testimonials">
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-1'></div>
                    <div className='col-lg-10'>
                        <h4 className='h4 pre-title'>
                            <FadeUp
                                text={ReactHtmlParser(props.preTitle)}
                                classList="up dn"
                            />
                        </h4>    
                        <h2 className='h2 section-title'>
                            {/* <SlideUp
                                text='<span class="outer"><span class="inner dn">Access</span></span> <span class="outer"><span class="inner dn">capital</span></span> <span class="outer"><span class="inner dn">that</span></span> <span class="outer"><span class="inner dn">complements</span></span> <span class="outer"><span class="inner dn">traditional</span></span> <span class="outer"><span class="inner dn">funding</span></span> <span class="outer"><span class="inner dn">options</span></span>'
                            /> */}
                            <FadeUp
                                text={ReactHtmlParser("Access capital that complements traditional funding options")}
                                classList="up dn"
                            />
                        </h2> 
                        <p className='section-description'>
                            <FadeUp
                                text={ReactHtmlParser(props.description)}
                                classList="up dn"
                            />
                        </p>
                    </div>
                    <div className='col-lg-1'></div>
                </div>
                <Slider {...settings}>
                    {props.list.map(function(object, i){
                        return (
                            <div className='list' key={i}>
                                <div className='bordered-section'>
                                    <div className='message'>
                                        <p>
                                            <FadeUp
                                                text={ReactHtmlParser(object.message)}
                                                classList="up dn"
                                            />
                                        </p>
                                    </div>
                                    <div className='box-footer'>
                                        <div className='icon'>
                                            <AddInviewImage
                                                src={object.image}
                                                classList='fade up dn'
                                            />
                                        </div>
                                        <div className='content'>
                                            <p>
                                                <strong>
                                                    <FadeUp
                                                        text={ReactHtmlParser(object.name)}
                                                        classList="left d1"
                                                    />
                                                </strong>
                                            </p>
                                            <p className='designation'>
                                                <FadeUp
                                                    text={ReactHtmlParser(object.designation)}
                                                    classList="left d2"
                                                />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>            
                        );
                    })}
                </Slider>
            </div>
        </section>
    );
};

export default TestimonialSection;