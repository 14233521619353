import { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props) => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {props.children}
      {
        // window.onbeforeunload = function () {
          // setTimeout(() => {
            // window.scrollTo(0, 0);
          // }, 500);
        // }
      }
    </>
  )
};

export default ScrollToTop;