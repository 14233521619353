import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

// Text Animation
import AddInview from '../../components/AddInview';

// Notification
import Notifications from '../Notifications';

// Email Script
import emailjs from 'emailjs-com';


const HomeHeaderForm = (props) => {
    const [inView, setInView] = useState(false);
    const [showPlaceholder, setShowPlaceholder] = useState(true);

    //notification states
    const [success, setSuccess] = useState(false);
    const [unsuccess, setUnSuccess] = useState(false);

    const handleChange = (event) => {
        if(event.target.value === ''){
            setShowPlaceholder(true);
        }
        else{
            setShowPlaceholder(false);
        }
    }

    const type = document.querySelector("[type = 'email']");

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm("service_qkpwosj", "template_93r8muo", e.target,"8bz1QRGr8jg53-erl"
        ).then(res => {
            console.log(res);
            setSuccess(true)
            setShowPlaceholder(true)
            type.value = ""
            setTimeout(() => {
                setSuccess(false)
            }, 3000)
        }).catch(err => {
            console.log(err)
            setUnSuccess(true)
            setTimeout(() => {
                setUnSuccess(false)
            }, 3000)
        });
    }

    return (
        <VisibilitySensor
            partialVisibility
            onChange={(isVisible) => {
                if(inView){
                    return
                }
                setInView(isVisible)
            }}
        >
            <div className={"get-funded-form " + props.classList +" " + (inView ? "in-view" : "")}>
                <form onSubmit={sendEmail}>
                    <input type="email" name="email" className='d2 email-imput' onChange={handleChange} required/>
                    <span className={'placeholder-text d4 ' + (showPlaceholder ? "visible" : "")}>Enter Email</span>
                    <a href='/' className='btn header-form-animate-btn' onClick={sendEmail}><AddInview text='' classList='button-bg d4'/><AddInview text='Get Funded' classList='fade up d4'/></a>
                    <input type="submit" className='btn header-form-animate-btn' style={{opacity: 0}}/>
                </form>

                <Notifications
                classList={"success " + (success === true ? "active" : "" )}
                image="data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='17.5' cy='17.5' r='16.5' stroke='%2354F3A6' stroke-width='2'/%3E%3Cpath d='M15 23L25 12' stroke='%2354F3A6' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M15 23L11 19' stroke='%2354F3A6' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A"
                title="Success"
                message="Thank you for contact, we will reach you soon."
                />

                <Notifications
                classList={"unsuccess " + (unsuccess === true ? "active" : "" )}
                image="data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='17.5' cy='17.5' r='16.5' stroke='%2354F3A6' stroke-width='2'/%3E%3Cpath d='M15 23L25 12' stroke='%2354F3A6' stroke-width='2' stroke-linecap='round'/%3E%3Cpath d='M15 23L11 19' stroke='%2354F3A6' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E%0A"
                title="Error"
                message="Something went wrong please try again later."
                />
                
            </div>
        </VisibilitySensor>
    );
};

export default HomeHeaderForm;