import React, { useState } from 'react';

import Lottie from 'react-lottie';
import VisibilitySensor from 'react-visibility-sensor';


const AnimateImage = (props) => {
    const [isStopped, setIsStopped] = useState(true);
    return (
        <VisibilitySensor
            partialVisibility
            onChange={(isVisible) => {
                if(!isStopped){
                    return
                }
                setTimeout(() => {
                    setIsStopped(!isVisible)
                }, props.time);
            }}
        >
            <Lottie 
                options={props.animationOptions}
                isStopped={isStopped}
                speed={props.speed}
            />
        </VisibilitySensor>
    );
};

export default AnimateImage;