import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

// Sections Import
import AnimateIcon from '../../components/AnimateIcon';

// Fade Animation
import FadeUp from '../../components/FadeUp';

const WhatWeDoBoxes = (props) => {
    const [inView, setInView] = useState(false);
    return (
        <VisibilitySensor
            partialVisibility
            onChange={(isVisible) => {
                if(inView){
                    return
                }
                setInView(isVisible)
            }}
        >
            <div className={"list " + props.classList +" " + (inView ? "in-view" : "")}>
                <div className='icon'>
                    {/* <img src={object1.icon.url} alt={object1.icon.alt} /> */}
                    <AnimateIcon 
                        animationOptions={props.animationOptions}
                        time={props.time}
                        speed={1}
                    />
                </div>
                <p>
                    <FadeUp
                        text={props.text}
                        classList={props.textClassList}
                    />
                </p>
            </div>
        </VisibilitySensor>
    );
};

export default WhatWeDoBoxes;