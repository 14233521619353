import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

const FadeUp = (props) => {
    const [inView, setInView] = useState(false);
    return (
        <VisibilitySensor
            partialVisibility
            onChange={(isVisible) => {
                if(inView){
                    return
                }
                setInView(isVisible)
            }}
        >
            <span className={"fade " + props.classList +" " + (inView ? "in-view" : "")}>{props.text}</span>
        </VisibilitySensor>
    );
};

export default FadeUp;