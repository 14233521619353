import React, {useEffect, useRef} from 'react';
import ReactHtmlParser from 'react-html-parser';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Animations Import
import Connect from '../../assets/animations/connect.json'
import GetFunded from '../../assets/animations/get-funded.json'
import SupportGrowth from '../../assets/animations/support-growth.json'

// Sections Import
import HowItWorksBoxes from '../../components/HowItWorksBoxes';

// Fade Animation
import FadeUp from '../../components/FadeUp';
import SlideUp from '../../components/SlideUp';

const HowItWorkSection = (props) => {
    const sectionRef = useRef();
    const gsapTriggerSection = '.how-it-work-section';

    const LottieDefaultOptions = [
        {
            loop: false,
            autoplay: false, 
            animationData: Connect,
        },
        {
            loop: false,
            autoplay: false, 
            animationData: GetFunded,
        },
        {
            loop: false,
            autoplay: false, 
            animationData: SupportGrowth,
        }
    ];
    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger)
        gsap.set("body", {
            // className: '+=white-bg',
            scrollTrigger: {
                trigger: gsapTriggerSection,
                start: "top center",
                end: "bottom top",
                ease: "ease",
                scrub: true,
                markers: false,
                onEnter: () => document.body.classList.add('light-bg'),
                // onLeave: () => document.body.classList.remove('white-bg'),
                onEnterBack: () => document.body.classList.remove('light-bg'),
                // onLeaveBack: () => document.body.classList.remove('white-bg'),
            }
        });
     }, []);
    return (
        <section className='how-it-work-section' ref={sectionRef} id="how-it-works">
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-1'></div>
                    <div className='col-lg-10'>
                        <h4 className='h4 pre-title'>
                            <FadeUp
                                text={ReactHtmlParser(props.preTitle)}
                                classList="up dn"
                            />
                        </h4>    
                        <h2 className='h2 section-title'>
                            {/* <SlideUp
                                text='<span class="outer"><span class="inner dn">Unlocking</span></span> <span class="outer"><span class="inner dn">potential</span></span> <span class="outer"><span class="inner dn">along</span></span> <span class="outer"><span class="inner dn">the</span></span> <span class="outer"><span class="inner dn">growth</span></span> <span class="outer"><span class="inner dn">journey</span></span>'
                            /> */}
                            <FadeUp
                                text={ReactHtmlParser("Unlocking potential along the growth journey")}
                                classList="up dn"
                            />
                        </h2> 
                        <div className='list-block'>
                            {props.list.map(function(object, i){
                                return (
                                    <HowItWorksBoxes
                                        count={'0'+ (i+1)}
                                        title={ReactHtmlParser(object.title)}
                                        description={ReactHtmlParser(object.description)}
                                        animationOptions={LottieDefaultOptions[i]}
                                        time="100"
                                        key={i}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className='col-lg-1'></div>
                </div>
            </div>
        </section>
    );
};

export default HowItWorkSection;