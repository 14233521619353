import React, {useEffect, useRef} from 'react';
import ReactHtmlParser from 'react-html-parser';
// import VisibilitySensor from 'react-visibility-sensor';
// import { gsap } from "gsap";
// import { ScrollTrigger } from "gsap/ScrollTrigger";

// Assets Import
// import PlayButton from '../../assets/images/play-btn.svg' 
// import Element1 from '../../assets/images/element-1.svg' 
// import SideElement1 from '../../assets/images/side-element-1.svg' 
import CircleElement1 from '../../assets/images/circle-element-1.svg' 
// import ScrollIcon from '../../assets/images/scroll-icon.svg' 

// Widgets Import
import BackgroundParticlesAnimation from '../../components/BackgroundParticlesAnimation';
import HomeHeaderForm from '../../components/HomeHeaderForm';

// Animations Import
import HomeHeroAnimation from '../../assets/animations/home-hero-header.json'
import DotsOrange from '../../assets/animations/dots-orange.json'
import MouseScroll from '../../assets/animations/mouse-scroll.json'
import Arrow from '../../assets/animations/home-header-bg-arrow.json'
import HeadingElement from '../../assets/animations/home-header-heading-element.json'

// Sections Import
import AnimateImage from '../../components/AnimateImage';
import HomeVideoBlock from '../../components/HomeVideoBlock';

// Text Animation
import SlideUp from '../../components/SlideUp';
import FadeUp from '../../components/FadeUp';
import AddInview from '../../components/AddInview';

const HomeHeroSection = (props) => {
    const sectionRef = useRef();
    // const gsapTriggerSection = '.home-hero-section';

    const LottieDefaultOptions = {
        loop: false,
        autoplay: true, 
        animationData: HomeHeroAnimation,
    };

    const DotsOrangeLottieDefaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: DotsOrange,
    };

    const MouseScrollLottieDefaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: MouseScroll,
    };

    const ArrowLottieDefaultOptions = {
        loop: false,
        autoplay: false, 
        animationData: Arrow,
    };

    const HeadingElementLottieDefaultOptions = {
        loop: true,
        autoplay: false, 
        animationData: HeadingElement,
    };

    useEffect(() => {
        // gsap.registerPlugin(ScrollTrigger)
        // gsap.set("body", {
        //     // className: '+=primary-bg',
        //     scrollTrigger: {
        //         trigger: gsapTriggerSection,
        //         start: "top center",
        //         end: "bottom top",
        //         ease: "ease",
        //         scrub: true,
        //         markers: false,
        //         onEnter: () => document.body.classList.add('primary-bg'),
        //         onLeave: () => document.body.classList.remove('primary-bg'),
        //         onEnterBack: () => document.body.classList.add('primary-bg'),
        //         onLeaveBack: () => document.body.classList.remove('primary-bg'),
        //     }
        // });
        // document.body.style.background = '#533FD7'; 
    }, []);

    return (
        <section className='home-hero-section' ref={sectionRef}>
            <div className='scroll-icon'>
                <a href="#what-we-do">
                    <AnimateImage 
                        animationOptions={MouseScrollLottieDefaultOptions}
                        time='100'
                        speed={1}
                    />
                </a>
            </div>
            <div className='arrow'>
                <AnimateImage 
                    animationOptions={ArrowLottieDefaultOptions}
                    time='800'
                    speed={1}
                />
            </div>
            <BackgroundParticlesAnimation/>
            <div className='side-element-1'>
                <AnimateImage 
                    animationOptions={DotsOrangeLottieDefaultOptions}
                    time='100'
                    speed={1}
                />
            </div>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className="image-block">
                            <img src={CircleElement1} alt="" className="circle-element-1" />
                            {/* <img src={props.heroImage.url} alt={props.heroImage.alt} /> */}
                            <AnimateImage 
                                animationOptions={LottieDefaultOptions}
                                time='800'
                                speed={1}
                            />
                        </div>
                    </div>
                    <div className='col-lg-1'></div>
                    <div className='col-lg-7'>
                        <div className='content-block'>
                            <h1 className='h1 section-title light-color'>
                                {/* <span className='third-color'>
                                    <AddInview text='REDEFINE' classList='fade left d2'/> <AddInview text='How' classList='fade left d3'/>
                                    <br/><AddInview text='YOU' classList='fade left d4'/> <AddInview text='GROW' classList='fade left d5'/> <AddInview text='YOUR' classList='fade left d6'/>
                                </span> */}
                                <FadeUp
                                    text={ReactHtmlParser("<span class='third-color'>REDEFINE HOW<br/>YOU GROW YOUR</span><br/>DIGITAL BUSINESS")}
                                    classList="up dn"
                                />
                                {/* <br/><AddInview text='DIGITAL' classList='fade up d7'/> <AddInview text='BUSINESS' classList='fade up d8'/> */}
                                {/* <AddInviewImage src={Element1} classList='element-1 d9'/> */}
                                <div className='element-1'>
                                    <AnimateImage 
                                        animationOptions={HeadingElementLottieDefaultOptions}
                                        time='2000'
                                        speed={1}
                                    />
                                </div>
                            </h1> 
                            
                            <p className='large light-color'>
                                {/* <SlideUp 
                                    text='<span class="outer"><span class="inner d12">Revenue</span></span> <span class="outer"><span class="inner d12">based</span></span> <span class="outer"><span class="inner d12">funding</span></span> <span class="outer"><span class="inner d12">and</span></span> <span class="outer"><span class="inner d12">execution</span></span> <span class="outer"><span class="inner d12">support</span></span> <span class="outer"><span class="inner d12">designed</span></span> <span class="outer"><span class="inner d12">for</span></span> <span class="outer"><span class="inner d12">early</span></span> <span class="outer"><span class="inner d12">stage</span></span> <span class="outer"><span class="inner d12">founders</span></span>'
                                /> */}
                                <FadeUp
                                    text={ReactHtmlParser("Revenue based funding and execution support designed for early stage founders")}
                                    classList="up dn"
                                />
                            </p>
                            <HomeHeaderForm
                                classList="d7"
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-2'></div>
                    <div className='col-lg-8'>
                        <HomeVideoBlock
                            videoThumbnailImage={props.videoThumbnailImage}
                        />
                    </div>
                    <div className='col-lg-2'></div>
                </div>
            </div>
        </section>
    );
};

export default HomeHeroSection;